
.coupon-grid-container {
    margin: 12px var(--spacing--lg);
}

.coupon-tabs {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    height: 33px;
    margin: 14px 0;
}

.coupon-tabs input[type="radio"] {
    margin: 0;
}

.coupons-grid {
    display: flex;
    flex-direction: row;
}

.coupons-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.coupons-column.left {
    width: 220px;
    padding-right: 27px;
}

.coupon-categories {
    order: 0;
}

.coupons-desktop-sorter {
    display: flex;
    align-items: center;
}

.coupons-sort-select {
    margin-top: 1px;
    width: 180px;
}

.coupons-desktop-sorter label {
    margin-top: 6px;
}

.coupons-sort-select__indicator {
    padding: 6px 8px !important;
}

.coupon-grid {
    position: relative;
    order: 0;
}

.coupons-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-left: -12px;
}

.coupons-row-no-coupons {
    display: flex;
    justify-content: center;
    margin-top: 160px;
    margin-bottom: 160px;
}

.coupon-tab {
    display: inline;
    background-color: #DDDDDD;
    border-top: var(--border-width) solid var(--color-gray--30);
    border-right: var(--border-width) solid var(--color-gray--30);
    font-weight: var(--font-weight--semi-bold) !important;
    padding: 10px;
}
.coupon-tab:nth-child(1) {
    border-left: var(--border-width) solid var(--color-gray--30);
}

.coupon-tab.checked {
    border-bottom: var(--border-width) solid white;
    background-color: white;
}

.coupon-tab input:checked+span {
    /* it will be needed */
}

.coupon-tab .radio-style {
    visibility: hidden;
    width: 0;
    margin: 0;
}

.coupon-guest-banner {
    background: #eee;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.coupon-guest-banner-item {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    font-weight: var(--font-weight--light);
    margin-bottom: 10px;
}

.coupon-guest-banner-item:last-child {
    margin-right: 0;
    margin-bottom: 0;
}

.coupon-guest-banner-item-icon {
    display: inline-block;
    background-color: var(--color-gray--30);
    background-clip: border-box;
    border-radius: 50%;
    background-position: 50%;
    transition: background-color 0.2s;
}

a.coupon-guest-banner-item-icon:hover {
    background-color: var(--color-brand--primary);
}

.coupon-guest-banner-item-icon.account-icon {
    background-image: url("https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/deals/images/coupons-person.png");
}
.coupon-guest-banner-item-icon.account-icon {
    background-image: url("https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/deals/images/coupons-person.png");
}

.coupon-guest-banner-item-icon.card-icon {
    background-image: url("https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/deals/images/coupons-card.png");
}

.coupon-guest-banner-item-icon.clip-icon {
    background-image: url("https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/deals/images/coupons-scissors.png");
}

.coupon-guest-banner-item-h5 {
    font-weight: var(--font-weight--bold);
}

.coupon-guest-banner-item-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.coupon-guest-banner-item-content .content-break {
    display: none;
}

.coupon-guest-banner-item-content p {
    display: block;
    line-height: 21px;
    font-size: 14px;
    font-weight: var(--font-weight--light);
    text-align: left;
}

.coupon-guest-banner-item-icon {
    background-size: 85%;
    min-width: 52px;
    height: 52px;
    width: 52px;
    margin-right: 12px;
}

.coupon-guest-banner a {
    color: var(--color-brand--primary);
    text-decoration: none;
}

.coupon-guest-banner a:hover {
    color: var(--color-brand--hover);
}

.coupon-guest-banner a:active {
    color: var(--color-brand--active);
}

.coupon-guest-banner a:visited {
    color: var(--color-brand--primary);
}

.coupon-category-filter-collapsed {
    display: none;
}

.coupon-category-filter-open {
    margin-top: 8px;
}

.coupon-category-filter{
    margin-bottom: 20px;
}

.button-icon-container {
    padding-right: 8px;
    display: flex;
    align-self: center;
}

.print-loaded-coupons-button-wrapper {
    margin-right: 16px;
}

.print-loaded-coupons-button {
    width: 246px;
}

.filter-and-sort-button {
    display: none
}

.printable-coupons-box-mobile {
    display: none
}

.bread-crumb-element:last-child {
    color: #777777;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ad-date-range {
    font-size: 16px;
    font-weight: var(--font-weight--semi-bold);
}

.ad-details-container {
    text-align: right;
}

.printable-coupons-box {
    background-color: var(--color-gray--10);
    border: var(--border-width) solid #e3e3e3;
    padding: 9px;
    border-radius: 3px;
    min-height: 20px;
}

.printable-coupons {
    font-size: 18px;
    font-weight: var(--font-weight--medium);
}

.slideOut {
    background-color: white;
    bottom: 0;
    position: fixed;
    width: 260px;
    top: 0;
    left: -260px;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.coupon-categories-slider {
    margin: 8px;
}

.disabled-coupons-page {
    display: none;
}

/* Display slideOut */

.showSlideOut {
    left: 0 !important;
    z-index: 9999;
}

.slideOutTab div {
    text-align: center;
    position: relative;
    right: 70px;
    top: 90px;
    background: #F2AB34;
    width: 180px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    writing-mode: lr-tb;
}

.disabled-coupons-page-active {
    max-width: 0;
    max-height: 0;
    padding: 0;
    margin: 0;
    border: none;
    display: block;
}

.disabled-coupons-page-active:after {
    content: '';
    cursor: pointer;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background: rgba(0,0,0,.5);
}

.loading-div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 1;
}

.ad-pdf-link {
    color: var(--color-brand--primary);
    font-size: 16px;
    font-weight: var(--font-weight--semi-bold);
}

.pdf-link-icon-container {
    display: flex;
    align-self: center;
    margin-right: 6px;
}

.local-ad-pagination-wrapper {
    display: block;
}

@media (min-width: 960px) {
    .coupon-guest-banner {
        flex-direction: row;
        justify-content: center;
    }

    .coupon-guest-banner-item {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-right: 50px;
        margin-bottom: 0;
    }

    .coupon-guest-banner-item-content {
        align-items: center;
    }
    .coupon-guest-banner-item-content p {
        text-align: center;
    }

    .coupon-guest-banner-item-content .content-break {
        display: inline;
    }

    .coupon-guest-banner-item-icon {
        background-size: 100%;
        margin-right: 0;
        margin-bottom: 8px;
        min-width: 100px;
        height: 100px;
        width: 100px;
    }
}

@media (max-width: 959px) {
    .ad-details-container {
        display: flex;
        justify-content: space-between;
        text-align: center;
        flex-direction: column;
        width: 100%;
        align-items: flex-start
    }

    .ad-date-range {
        margin-bottom: 6px;
    }

    .coupons-grid {
        align-items: center;
        flex-direction: column;
    }

    .coupon-categories-non-slider {
        display: none;
    }

    .coupons-desktop-sorter {
        display: none;
    }

    .coupons-sort-select {
        width: 100%;
    }

    .coupon-grid {
        min-height: 300px;
        order: 2;
    }

    .print-loaded-coupons-button-wrapper {
        display: none
    }

    .filter-and-sort-button {
        display: block
    }

    .printable-coupons-box-mobile {
        display: block
    }

    .printable-coupons-image {
        text-align: center;
    }
}

@media (min-width: 60rem) {
    .coupons-view {
        margin: 12px 40px;
    }

    .coupon-grid-container {
        margin: 12px 40px;
    }

    .coupons-row {
        margin-left: 0;
    }
}

@media (min-width: 90rem) {
    .coupons-view {
        margin: 12px auto;
        max-width: var(--breakpoint-screen--x-lg);
    }

    .coupon-grid-container {
        margin: 12px auto;
        max-width: var(--breakpoint-screen--x-lg);
    }
}


@media print {
    .hiddenForPrint {
        display: none;
    }

    /*This is to hide the Medallia feedback triangle in the bottom right corner while printing*/
    #kampyleButtonContainer {
        display: none;
    }

    span {
        font-size: 8px;
    }
}

.local-ad-view-container {
    margin: 12px auto;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.local-ad-page-count {
    text-align: center;
}

.local-ad-page-arrow {
    align-self: center;
}

.next {
    border-top: var(--border-width) solid var(--color-gray--20);
    border-bottom: var(--border-width) solid var(--color-gray--20);
    border-right: var(--border-width) solid var(--color-gray--20);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    width: 44px;
}

.previous {
    border: var(--border-width) solid var(--color-gray--20);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    width: 44px;
    justify-content: center;
}
